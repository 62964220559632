<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10 ">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-6 md:p-7 after:content-['1'] after:text-white after:text-lg md:after:text-2xl">
      </div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['2']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['3']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['4']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['5']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['6']"></div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 border-2 rounded-r border-thired">
        <div class="progress-line"></div>
      </div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 rounded-l border-2 border-thired"></div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">

      <!-- START selectedOption -->
      <div class=" space-y-2 text-lg w-[300px] md:w-auto">
        <p>نطاق المتقدم للوظيفة:</p>
        <div class="flex items-center gap-x-3">
          <input class="h-4 w-4 border-gray-300 text-secondary" type="radio" name="selectedOption"
            v-model="selectedOption" value="السعوديين ومن يعامل معاملة السعوديين">
          <label class="">السعوديين ومن يعامل معاملة السعوديين</label>
        </div>

        <div class="flex items-center gap-x-3">
          <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="selectedOption" v-model="selectedOption"
           value="المقيميين بالمملكة العربية السعودية">
          <label class="">المقيميين بالمملكة العربية السعودية</label>
        </div>

        <div class="flex items-center gap-x-3">
          <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="selectedOption" v-model="selectedOption"
             value="المتقدمين من خارج المملكة العربية السعودية">
          <label class=" block">المتقدمين من خارج المملكة العربية السعودية</label>
        </div>
        <div v-if="showValidationError">
          <p class="errors">يرجى اختيار خيار واحد على الأقل</p>
        </div>
      </div>
      <!-- END selectedOption -->

      <!-- button -->
      <div @click="handleNext" class="flex justify-center mt-12">
        <button :class="{
          'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded-xl text-white font-bold cursor-pointer': true,
          'bg-secondary': !isDataEmpty,
          'bg-slate-400': isDataEmpty,
          'cursor-auto': isDataEmpty
        }">
          <span>انتقل للخطوة التالية</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: '',
      showValidationError: false
    }
  },
  methods: {
    handleNext() {
      if (this.selectedOption !== '') {
        localStorage.setItem('selectedOption', this.selectedOption)
        this.$emit('update-step', 'step2'); // Emit an event to update the step in the parent component
      } else {
        this.showValidationError = true; // Set the flag to display the custom error message
      }
    },
  },
  computed: {
    isDataEmpty() {
      if (this.selectedOption == '') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>



<style scoped>
.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}
</style>