<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['2'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['3'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['4'] after:text-white"></div>
      <div class="line">
        <div class="progress-bar"></div>
      </div>
      <div class="step bg-thired p-6 md:p-7 after:content-['5'] after:text-white after:text-lg md:after:text-2xl sc1">
      </div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['6']"></div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 rounded-r bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 border-2 border-thired">
        <div class="progress-line"></div>
      </div>
      <div class="h-2 w-10 rounded-l border-2 border-thired"></div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">
      <h1 class=" text-xl text-red-700 text-center mb-10">يجب ان تكون اسماء الملفات باللغة الانجليزية</h1>
      <!-- START NATIONAL ID -->
      <div class="flex flex-col md:flex-row justify-start items-center gap-6">
        <!-- START THE DIV OF THE NATIONAL ID -->
        <div class="space-y-2">
          <label for="national_id">الهوية الوطنية / الاقامة:</label>
          <div class="">
            <input @change="handleIdFile"
              class="file:ml-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-bold file:bg-mainColor file:text-black file:cursor-pointer cursor-pointer w-[300px]"
              type="file" accept=".pdf" name="national_id" id="national_id" v-validate.continues="'required'">
          </div>
          <p class="errors">{{ errors.first('national_id') }}</p>
          <div v-if="showValidationError">
            <p v-if="national_id == null && errors.first('national_id') == null" class="errors">يرجى اختيار الملف</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE NATIONAL ID -->

        <!-- START THE DIV OF THE NATIONAL ADDRESS -->
        <div class="space-y-2">
          <label for="national_address">العنوان الوطني:</label>
          <div class="">
            <input @change="handleAddressFile" accept=".pdf" v-validate.continues="'required'" type="file"
              name="national_address" id="national_address"
              class="file:ml-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-bold file:bg-mainColor file:text-black file:cursor-pointer cursor-pointer w-[300px]">
          </div>
          <p class="errors">{{ errors.first('national_address') }}</p>
          <div v-if="showValidationError">
            <p v-if="national_address == null && errors.first('national_address') == null" class="errors">
              يرجى اختيار الملف
            </p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE NATIONAL ADDRESS -->
      </div>
      <!-- END NATIONAL ID -->

      <hr>

      <!-- START CERTIFICATE & RESUME-->
      <div class="section">
        <!-- START THE DIV OF THE CERTIFICATE -->
        <div class="space-y-2">
          <label for="">شهادة المؤهل:</label>
          <div class="">
            <input @change="handleCertificateFile" accept=".pdf" v-validate.continues="'required'" type="file"
              name="certificate"
              class="file:ml-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-bold file:bg-mainColor file:text-black file:cursor-pointer cursor-pointer w-[300px]">
          </div>
          <p class="errors">{{ errors.first('certificate') }}</p>
          <div v-if="showValidationError">
            <p v-if="certificate == null && errors.first('certificate') == null" class="errors">يرجى اختيار الملف
            </p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE CERTIFICATE -->

        <!-- START THE DIV OF THE RESUME -->
        <div class="space-y-2 w-[300px] md:w-auto">
          <label class="" for="">السيرة الذاتية مع تفاصيل الخبرات إن وجدت بملف واحد:</label>
          <div class="">
            <input @change="handleResumeFiles" accept=".pdf" v-validate.continues="'required'" type="file" name="resume"
              class="file:ml-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-bold file:bg-mainColor file:text-black file:cursor-pointer cursor-pointer w-[300px]">
          </div>
          <p class="errors">{{ errors.first('resume') }}</p>
          <div v-if="showValidationError">
            <p v-if="resumeAndExperince == null && errors.first('resume') == null" class="errors">يرجى اختيار الملف
            </p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE RESUME -->
      </div>
      <!-- END CERTIFICATE & RESUME -->

      <div class=" flex justify-center items-center gap-4">
        <p v-if="this.isLoading" class=" text-thired font-bold text-lg">جاري رفع الملفات</p>
        <div v-if="this.isLoading" class="loader"></div>
      </div>

      <!-- THE BUTTON -->
      <div class="flex justify-center gap-6">
        <div class="w-[300px] text-left">
          <button @click="handlePrevious"
            class="bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer">الخطوة
            السابقة</button>
        </div>
        <div class="w-[300px] text-right">
          <button @click="handleNext" :class="{
            'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
            'bg-secondary': !isDataEmpty,
            'bg-slate-400': isDataEmpty,
            'cursor-pointer': !isDataEmpty
          }">
            الخطوة التالية
          </button>
        </div>
      </div>
      <!-- END OF THE BUTTON -->
    </div>
  </div>
</template>
  
<script>
import { supabase } from '../../supabase.js';
import Swal from 'sweetalert2'

export default {
  name: 'step-five',
  data() {
    return {
      national_id: null,
      national_address: null,
      certificate: null,
      resumeAndExperince: null,
      isLoading: null,
      showValidationError: false
    };
  },
  methods: {
    async handleNext() {
      if (
        this.national_id &&
        this.national_address &&
        this.certificate &&
        this.resumeAndExperince &&
        !this.errors.has('national_id') &&
        !this.errors.has('national_address') &&
        !this.errors.has('certificate') &&
        !this.errors.has('resumeAndExperince')
      ) {
        // Set loading state to true
        this.isLoading = true;

        try {
          // Upload the national_id file to Supabase storage

          const extension = this.national_id.name.split('.').pop();  // Get the extension from the original file name
          const timestamp = new Date().getTime();  // Get the current timestamp


          const { data: item, error } = await supabase.storage
          .from('nationalID')
          .upload(`/nationalID/${this.national_id.name}_${timestamp}.${extension}`, this.national_id);
          if (error) {
            console.log(error);
            this.national_id = null
            return;
          } else {
            console.log('Supabase Upload Data:', item);
            // Get the public URL of the uploaded file
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('nationalID')
              .getPublicUrl(`/nationalID/${this.national_id.name}_${timestamp}.${extension}`);
            if (publicUrlData) {
              const publicUrl = publicUrlData;
              console.log('Public URL:', publicUrl);
              localStorage.setItem('national_id', publicUrl.publicUrl)
            } else {
              console.error('Supabase Public URL Error:', urlError);
            }
          }

          // Upload the national_address file to Supabase storage

          const extension1 = this.national_id.name.split('.').pop(); // Get the extension from the original file name
          const timestamp1 = new Date().getTime(); // Get the current timestamp

          const { data: item2, error: error2 } = await supabase.storage
        .from('national_address')
        .upload(`/national_address/${this.national_address.name}_${timestamp1}.${extension1}`, this.national_address);

          if (error2) {
            this.national_address = null
            console.log(error2);
            return;
          } else {
            console.log('Supabase Upload Data:', item2);

            // Get the public URL of the uploaded file
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('national_address')
              .getPublicUrl(`/national_address/${this.national_address.name}_${timestamp1}.${extension1}`);

            if (publicUrlData) {
              const publicUrl = publicUrlData;
              console.log('Public URL:', publicUrl);
              localStorage.setItem('national_address', publicUrl.publicUrl)

            } else {
              console.error('Supabase Public URL Error:', urlError);
            }
          }

          // Upload the certificate file to Supabase storage

          const extension2 = this.certificate.name.split('.').pop(); // Get the extension from the original file name
          const timestamp2 = new Date().getTime(); // Get the current timestamp

          const { data: item3, error: error3 } = await supabase.storage
            .from('certificate')
            .upload(`/certificate/${this.certificate.name}_${timestamp2}.${extension2}`, this.certificate);

          if (error3) {
            this.certificate = null
            console.log(error3);
            return;
          } else {
            console.log('Supabase Upload Data:', item3);

            // Get the public URL of the uploaded file
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('certificate')
              .getPublicUrl(`/certificate/${this.certificate.name}_${timestamp2}.${extension2}`);

            if (publicUrlData) {
              const publicUrl = publicUrlData;
              console.log('Public URL:', publicUrl);
              localStorage.setItem('certificate', publicUrl.publicUrl)

            } else {
              console.error('Supabase Public URL Error:', urlError);
            }
          }

          // Upload the resumeAndExperince file to Supabase storage
          const extension3 = this.resumeAndExperince.name.split('.').pop(); // Get the extension from the original file name
          const timestamp3 = new Date().getTime(); // Get the current timestamp

          const { data: item4, error: error4 } = await supabase.storage
            .from('resumeAndExperince')
            .upload(`/resumeAndExperince/${this.resumeAndExperince.name}_${timestamp3}.${extension3}`, this.resumeAndExperince);

          if (error4) {
            this.resumeAndExperince = null
            console.log(error4);
            return;
          } else {
            console.log('Supabase Upload Data:', item4);

            // Get the public URL of the uploaded file
            const { data: publicUrlData, error: urlError } = await supabase
              .storage
              .from('resumeAndExperince')
              .getPublicUrl(`/resumeAndExperince/${this.resumeAndExperince.name}_${timestamp3}.${extension3}`);

            if (publicUrlData) {
              const publicUrl = publicUrlData;
              console.log('Public URL:', publicUrl);
              localStorage.setItem('resumeAndExperince', publicUrl.publicUrl)

            } else {
              console.error('Supabase Public URL Error:', urlError);
            }
          }

          // Set loading state to false
          this.isLoading = false;

          this.$emit('update-step', 'step6');
        } catch (e) {
          // Handle any errors that occur during the upload process
          console.error('Upload Error:', e);
          // Set loading state to false
          this.isLoading = false;
        }

      } else {
        this.showValidationError = true; // Set the flag to display the custom error message
      }
    },
    handlePrevious() {
      this.$emit('update-step', 'step4'); // Emit an event to update the step in the parent component
    },
    //METHOD OF THE NATIONAL ID
    handleIdFile(event) {
      const file = event.target.files[0];

      // Regular expression to match Arabic characters
      const fileName = file.name;
      const arabicCharactersRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFBC2\uFBD3-\uFD3D\uFD50-\uFD8F\uFD92-\uFDC7\uFDF0-\uFDFD\uFE70-\uFEFC]/;

      if (arabicCharactersRegex.test(fileName)) {
        Swal.fire({
          icon: "error",
          title: "...خطأ",
          text: "يجب ان تكون اسماء الملفات باللغة الانجليزية",
          confirmButtonText: "حسنا",
          confirmButtonColor: "#57b6b2",
          confirmButtonBorderColor: "none",
          confirmButtonBoxShadow: "none",
          confirmButtonOutline: "none"
        });
        this.national_id = null;
        event.target.value = null; // Reset the file input value to clear the selected file
      } else {
        // Check if the file exists in the Supabase bucket
        supabase.storage
          .from('nationalID')
          .list('nationalID')
          .then(({ data }) => {
            const fileExists = data.some(item => item.name === fileName);
            if (fileExists) {
              // File exists in the bucket
              Swal.fire({
                icon: "error",
                title: "...خطأ",
                text: "يوجد ملف بهذا الاسم مسبقا. الرجاء ادخال اسم اخر لملف الهوية الوطنية/الإقامة",
                confirmButtonText: "حسنا",
                confirmButtonColor: "#57b6b2",
                confirmButtonBorderColor: "none",
                confirmButtonBoxShadow: "none",
                confirmButtonOutline: "none"
              });
              this.national_id = null;
              event.target.value = null; // Reset the file input value to clear the selected file
            } else {
              // File does not exist in the bucket
              this.national_id = file;
            }
          })
          .catch(error => {
            console.error('Error checking file in Supabase:', error);
            // Handle the error appropriately
          });
      }
    },
    //METHOD OF THE NATIONAL ADDRESS
    handleAddressFile(event) {
      const file = event.target.files[0];
      this.national_address = file;
      // Regular expression to match Arabic characters
      const fileName = file.name;
      const arabicCharactersRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFBC2\uFBD3-\uFD3D\uFD50-\uFD8F\uFD92-\uFDC7\uFDF0-\uFDFD\uFE70-\uFEFC]/;
      if (arabicCharactersRegex.test(fileName)) {
        Swal.fire({
          icon: "error",
          title: "...خطأ",
          text: "يجب ان تكون اسماء الملفات باللغة الانجليزية",
          confirmButtonText: "حسنا",
          confirmButtonColor: "#57b6b2",
          confirmButtonBorderColor: "none",
          confirmButtonBoxShadow: "none",
          confirmButtonOutline: "none"
        });
        this.national_address = null;
        event.target.value = null; // Reset the file input value to clear the selected file

      } else {
        // Check if the file exists in the Supabase bucket
        supabase.storage
          .from('national_address')
          .list('national_address')
          .then(({ data }) => {
            const fileExists = data.some(item => item.name === fileName);
            if (fileExists) {
              // File exists in the bucket
              Swal.fire({
                icon: "error",
                title: "...خطأ",
                text: "يوجد ملف بهذا الاسم مسبقا. الرجاء ادخال اسم اخر لملف العنوان الوطني",
                confirmButtonText: "حسنا",
                confirmButtonColor: "#57b6b2",
                confirmButtonBorderColor: "none",
                confirmButtonBoxShadow: "none",
                confirmButtonOutline: "none"
              });
              this.national_address = null;
              event.target.value = null; // Reset the file input value to clear the selected file
            } else {
              // File does not exist in the bucket
              this.national_address = file;
            }
          })
          .catch(error => {
            console.error('Error checking file in Supabase:', error);
            // Handle the error appropriately
          });
      }
    },
    //METHOD OF THE CERTIFICATE
    handleCertificateFile(event) {
      const file = event.target.files[0];
      this.certificate = file;

      // Regular expression to match Arabic characters
      const fileName = file.name;
      const arabicCharactersRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFBC2\uFBD3-\uFD3D\uFD50-\uFD8F\uFD92-\uFDC7\uFDF0-\uFDFD\uFE70-\uFEFC]/;
      if (arabicCharactersRegex.test(fileName)) {
        Swal.fire({
          icon: "error",
          title: "...خطأ",
          text: "يجب ان تكون اسماء الملفات باللغة الانجليزية",
          confirmButtonText: "حسنا",
          confirmButtonColor: "#57b6b2",
          confirmButtonBorderColor: "none",
          confirmButtonBoxShadow: "none",
          confirmButtonOutline: "none"
        }); this.certificate = null;
        event.target.value = null; // Reset the file input value to clear the selected file

      } else {
        // Check if the file exists in the Supabase bucket
        supabase.storage
          .from('certificate')
          .list('certificate')
          .then(({ data }) => {
            const fileExists = data.some(item => item.name === fileName);
            if (fileExists) {
              // File exists in the bucket
              Swal.fire({
                icon: "error",
                title: "...خطأ",
                text: "يوجد ملف بهذا الاسم مسبقا. الرجاء ادخال اسم اخر لملف شهادة المؤهل",
                confirmButtonText: "حسنا",
                confirmButtonColor: "#57b6b2",
                confirmButtonBorderColor: "none",
                confirmButtonBoxShadow: "none",
                confirmButtonOutline: "none"
              });
              this.certificate = null;
              event.target.value = null; // Reset the file input value to clear the selected file
            } else {
              // File does not exist in the bucket
              this.certificate = file;
            }
          })
          .catch(error => {
            console.error('Error checking file in Supabase:', error);
            // Handle the error appropriately
          });
      }
    },
    // METHOD OF THE RESUME AND EXPERIENCE
    handleResumeFiles(event) {
      const files = event.target.files[0];
      this.resumeAndExperince = files;

      // Regular expression to match Arabic characters
      const fileName = files.name;
      const arabicCharactersRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFBC2\uFBD3-\uFD3D\uFD50-\uFD8F\uFD92-\uFDC7\uFDF0-\uFDFD\uFE70-\uFEFC]/;
      if (arabicCharactersRegex.test(fileName)) {
        Swal.fire({
          icon: "error",
          title: "...خطأ",
          text: "يجب ان تكون اسماء الملفات باللغة الانجليزية",
          confirmButtonText: "حسنا",
          confirmButtonColor: "#57b6b2",
          confirmButtonBorderColor: "none",
          confirmButtonBoxShadow: "none",
          confirmButtonOutline: "none"
        }); this.resumeAndExperince = null;
        event.target.value = null; // Reset the file input value to clear the selected file

      } else {
        // Check if the file exists in the Supabase bucket
        supabase.storage
          .from('resumeAndExperince')
          .list('resumeAndExperince')
          .then(({ data }) => {
            const fileExists = data.some(item => item.name === fileName);
            if (fileExists) {
              // File exists in the bucket
              Swal.fire({
                icon: "error",
                title: "...خطأ",
                text: "يوجد ملف بهذا الاسم مسبقا. الرجاء ادخال اسم اخر لملف السيرة الذاتية",
                confirmButtonText: "حسنا",
                confirmButtonColor: "#57b6b2",
                confirmButtonBorderColor: "none",
                confirmButtonBoxShadow: "none",
                confirmButtonOutline: "none"
              });
              this.resumeAndExperince = null;
              event.target.value = null; // Reset the file input value to clear the selected file
            } else {
              // File does not exist in the bucket
              this.resumeAndExperince = files;
            }
          })
          .catch(error => {
            console.error('Error checking file in Supabase:', error);
            // Handle the error appropriately
          });
      }
    },
  },
  computed: {
    isDataEmpty() {
      if (this.national_id <= 0 || this.national_address <= 0 || this.certificate <= 0 || this.resumeAndExperince <= 0 ||
        this.errors.has('national_id') || this.errors.has('national_address') || this.errors.has('certificate') || this.errors.has('resumeAndExperince')) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>


<style scoped>
.progress-bar {
  height: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  width: 100%;
  animation: progressAnimationStrike 2s;
}

.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}

.sc1 {
  animation: scale 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes scale {
  from {
    padding: 20px;
    background: transparent;
  }

  to {
    padding: 28px;
    background: #c0d889;
  }
}


/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.loader {
  width: 30px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #c0d889;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}
</style>