<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['2'] after:text-white"></div>
      <div class="line">
        <div class="progress-bar"></div>
      </div>
      <div class="step bg-thired p-6 md:p-7 after:content-['3'] after:text-white after:text-lg md:after:text-2xl sc1">
      </div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['4']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['5']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['6']"></div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 rounded-r bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 border-2 border-thired">
        <div class="progress-line"></div>
      </div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 rounded-l border-2 border-thired"></div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">
      <div class="section">
        <!-- START THE DIV OF THE QUALIFICATION SELECT -->
        <div class="space-y-2">
          <label class="pr-2" for="selectedQualification">المؤهل الدارسي</label>
          <div>
            <select v-validate.continues="'required'" name="selectedQualification" id="selectedQualification"
              class="select h-[39.2px]" v-model="selectedQualification">
              <option disabled value="">اختيار</option>
              <option v-for="qualification in qualifications" :value="qualification.value" :key="qualification.value">{{
                qualification.label }}</option>
            </select>
          </div>
          <p class="errors">{{ errors.first('selectedQualification') }}</p>
          <div v-if="showValidationError">
            <p v-if="selectedQualification === ''" class="errors">يرجى اختيار احدى الخيارات </p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE QUALIFICATION SELECT -->
        <!-- START OF THE DIV OF THE SPECIALIZATION -->
        <div class="space-y-2">
          <label class="pr-2" for="specialization">التخصص</label>
          <div>
            <input class="input" type="text" name="specialization" id="specialization" v-model="specialization"
              placeholder="التخصص" v-validate.continues="'required|alpha_spaces|min:3'" maxlength="50">
          </div>
          <p class="errors">{{ errors.first('specialization') }}</p>
          <div v-if="showValidationError">
            <p v-if="specialization == '' && errors.first('specialization') == null" class="errors">
              يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE SPECIALIZATION -->
      </div>

      <!-- START OF THE DIV OF THE SPECIALIZATION -->
      <div class="section">
        <div class="space-y-2">
          <label class="pr-2" for="specialization_resource">مصدر المؤهل</label>
          <div>
            <input class="input" type="text" name="specialization_resource" id="specialization_resource"
              v-model="specialization_resource" placeholder="مصدر المؤهل"
              v-validate.continues="'required|alpha_spaces|min:3'" maxlength="50">
          </div>
          <p class="errors">{{ errors.first('specialization_resource') }}</p>
          <div v-if="showValidationError">
            <p v-if="specialization_resource == '' && errors.first('specialization_resource') == null"
              class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END OF THE DIV OF THE SPECIALIZATION -->

      <hr>

      <!-- START OF THE DIV OF THE STUDY MODE -->
      <div class="section">
        <div class="space-y-2 w-[300px]">
          <label class="" for="">طريقة الدراسة</label>

          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondary" type="checkbox" name="studyMode" id="studyMode1"
              value="انتظام" v-model="studyMode">
            <label for="studyMode1">انتظام</label>
          </div>

          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondary" type="checkbox" name="studyMode" id="studyMode2"
              value="انتساب" v-model="studyMode">
            <label for="studyMode2">انتساب</label>
          </div>

          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondary" type="checkbox" name="studyMode" id="studyMode3"
              value="عن بعد - إلكتروني" v-model="studyMode">
            <label for="studyMode3">عن بعد - إلكتروني</label>
          </div>
          <div v-if="showValidationError">
            <p v-if="studyMode == ''" class="errors">يرجى اختيار خيار واحد على الأقل</p>
          </div>
        </div>
      </div>
      <!-- END OF THE DIV OF THE STUDY MODE -->

      <hr>

      <div class="section">
        <!-- START OF THE DIV OF THE COUNTRY -->
        <div class="space-y-2">
          <label class="pr-2" for="country">الدولة</label>
          <div>
            <input class="input" type="text" name="country" id="country" v-model="country" placeholder="الدولة"
              v-validate.continues="'required|alpha_spaces|min:3'" maxlength="50">
          </div>
          <p class="errors">{{ errors.first('country') }}</p>
          <div v-if="showValidationError">
            <p v-if="country == '' && errors.first('country') == null" class="errors">يرجى تعبئة
              الحقل</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE COUNTRY -->
        <!-- START OF THE DIV OF THE CITY -->
        <div class="space-y-2">
          <label class="pr-2" for="">المدينة</label>
          <div>
            <input class="input" type="text" name="city" v-model="city" placeholder="المدينة"
              v-validate.continues="'required|alpha_spaces|min:3'" maxlength="50">
          </div>
          <p class="errors">{{ errors.first('city') }}</p>
          <div v-if="showValidationError">
            <p v-if="city == '' && errors.first('city') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE CITY -->
      </div>

      <hr>

      <div class="section">
        <!-- START OF THE DIV OF THE GRADUATION DATE -->
        <div class="space-y-2">
          <label class="pr-2" for="">تاريخ التخرج</label>
          <div>
            <input class="input" v-validate.continues="'required'" maxlength="50" v-model="graduation_date"
              name="graduation_date" type="date">
          </div>
          <p class="errors">{{ errors.first('graduation_date') }}</p>
          <div v-if="showValidationError">
            <p v-if="graduation_date == '' && errors.first('graduation_date') == null"
              class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE GRADUATION DATE -->
        <!-- START OF THE DIV OF THE GPA -->
        <div class="space-y-2">
          <label class="pr-2" for="">المعدل التراكمي</label>
          <div>
            <input class="input" v-validate.continues="'required|min_value:0|max_value:100'" maxlength="50" v-model="gpa"
              name="gpa" type="text" placeholder="المعدل التراكمي">
          </div>
          <p class="errors">{{ errors.first('gpa') }}</p>
          <div v-if="showValidationError">
            <p v-if="gpa == '' && errors.first('gpa') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF THE DIV OF THE GPA -->
      </div>

      <hr>

      <!-- START OF THE DIV OF THE Academic appreciation -->
      <div class="section">
        <div class="space-y-2">
          <label class="pr-2" for="">التقدير</label>
          <div>
            <select v-validate.continues="'required'" name="appreciation" class="select h-[39.2px]"
              v-model="academic_appreciation">
              <option disabled value="">اختيار</option>
              <option v-for="appreciation in appreciations" :value="appreciation" :key="appreciation">{{ appreciation }}
              </option>
            </select>
          </div>
          <p class="errors">{{ errors.first('appreciation') }}</p>
          <div v-if="showValidationError">
            <p v-if="academic_appreciation === ''" class="errors">يرجى اختيار احدى الخيارات </p>
          </div>
        </div>
      </div>
      <!-- END OF THE DIV OF THE Academic appreciation -->

      <!-- THE BUTTON -->
      <div class="flex justify-center gap-6">
        <div class="w-[300px] text-left">
          <button @click="handlePrevious"
            class="bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer">الخطوة
            السابقة</button>
        </div>
        <div class="w-[300px] text-right">
          <button @click="handleNext" :class="{
            'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
            'bg-secondary': !isDataEmpty,
            'bg-slate-400': isDataEmpty,
            'cursor-auto': isDataEmpty
          }">
            الخطوة التالية
          </button>
        </div>
      </div>
      <!-- END OF THE BUTTON -->
    </div>


  </div>
</template>


<script>
export default {
  data() {
    return {
      selectedQualification: '',
      specialization: '',
      specialization_resource: '',
      studyMode: [],
      country: '',
      city: '',
      graduation_date: '',
      gpa: '',
      academic_appreciation: '',

      qualifications: [
        { label: 'ابتدائي', value: 'ابتدائي' },
        { label: 'متوسط', value: 'متوسط' },
        { label: 'ثانوي', value: 'ثانوي' },
        { label: 'دبلوم', value: 'دبلوم' },
        { label: 'بكالوريس', value: 'بكالوريس' },
        { label: 'ماجستير', value: 'ماجستير' },
        { label: 'دكتوراه', value: 'دكتوراه' },
      ],
      appreciations: ['ممتاز', 'جيد جدا', 'جيد', 'مقبول'],
      showValidationError: false
    };
  },
  methods: {
    handleNext() {
      if (this.selectedQualification !== '' && this.specialization !== '' && this.specialization_resource !== '' && this.studyMode.length !== 0 &&
        this.country !== '' && this.city !== '' && this.graduation_date !== '' && this.gpa !== '' &&
        this.academic_appreciation !== '' &&
        !this.errors.has('selectedQualification') && !this.errors.has('specialization') && !this.errors.has('specialization_resource') && !this.errors.first('studyMode') &&
        !this.errors.has('country') && !this.errors.has('city') && !this.errors.has('graduation_date') && !this.errors.first('gpa') &&
        !this.errors.first('academic_appreciation')) {

        localStorage.setItem('selectedQualification', this.selectedQualification)
        localStorage.setItem('specialization', this.specialization)
        localStorage.setItem('specialization_resource', this.specialization_resource)
        localStorage.setItem('studyMode', this.studyMode)
        localStorage.setItem('country', this.country)
        localStorage.setItem('city', this.city)
        localStorage.setItem('graduation_date', this.graduation_date)
        localStorage.setItem('gpa', this.gpa)
        localStorage.setItem('academic_appreciation', this.academic_appreciation)

        this.$emit('update-step', 'step4'); // Emit an event to update the step in the parent component
      } else {
        this.showValidationError = true; // Set the flag to display the custom error message
      }
    },
    handlePrevious() {
      this.$emit('update-step', 'step2'); // Emit an event to update the step in the parent component
    },
  },
  computed: {
    isDataEmpty() {
      if (this.selectedQualification == '' || this.specialization == '' || this.specialization_resource == '' || this.studyMode.length == 0 ||
        this.country == '' || this.city == '' || this.graduation_date == '' || this.gpa == '' ||
        this.academic_appreciation == '' ||
        this.errors.has('selectedQualification') || this.errors.has('specialization') || this.errors.has('specialization_resource') || this.errors.has('maritalStates') ||
        this.errors.has('country') || this.errors.has('city') || this.errors.has('graduation_date') || this.errors.has('gpa') ||
        this.errors.has('academic_appreciation')) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.progress-bar {
  height: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  width: 100%;
  animation: progressAnimationStrike 2s;
}

.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}

.sc1 {
  animation: scale 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes scale {
  from {
    padding: 20px;
    background: transparent;
  }

  to {
    padding: 28px;
    background: #c0d889;
  }
}</style>