<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"></div>
      <div class="line">
        <div class="progress-bar"></div>
      </div>
      <div class="step bg-thired p-6 md:p-7 after:content-['2'] after:text-white after:text-lg md:after:text-2xl sc1">
      </div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['3']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['4']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['5']"></div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['6']"></div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 rounded-r bg-thired"></div>
      <div class="h-2 w-10 border-2 border-thired">
        <div class="progress-line"></div>
      </div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 rounded-l border-2 border-thired"></div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">

      <!-- START name -->
      <div class="section">
        <div class="space-y-2">
          <label class="pr-2" for="name">الأسم الرباعي:</label>
          <div>
            <input class="input" type="text" name="fullName" id="name" v-model="name" placeholder="الأسم الرباعي"
              v-validate="'required|alpha_spaces|min:10'">
          </div>
          <p class="errors">{{ errors.first('fullName') }}</p>
          <div v-if="showValidationError">
            <p v-if="name == '' && errors.first('fullName') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END name -->

      <hr>

      <!-- START gender & maritalStates -->
      <div class="section">
        <!-- gender -->
        <div class=" space-y-2 w-[300px]">
          <p>الجنس:</p>
          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="gender" id="male" v-model="gender"
              value="ذكر">
            <label for="male" class=" block">ذكر</label>
          </div>
          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="gender" id="female" v-model="gender"
              value="أنثى">
            <label for="female" class=" block">أنثى</label>
          </div>
          <div v-if="showValidationError">
            <p v-if="gender == ''" class="errors">يرجى اختيار احدى الخيارات</p>
          </div>
        </div>
        <!-- maritalStates -->
        <div class=" space-y-2 w-[300px]">
          <p>الحالة الإجتماعية:</p>
          <!-- single -->
          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="maritalStates" id="single"
              v-model="maritalStates" value="أعزب">
            <label for="single">أعزب</label>
          </div>
          <!-- married -->
          <div class="flex items-center gap-x-3">
            <input class="h-4 w-4 border-gray-300 text-secondar" type="radio" name="maritalStates" id="married"
              v-model="maritalStates" value="متزوج">
            <label for="married" class="">متزوج</label>
          </div>
          <div v-if="showValidationError">
            <p v-if="maritalStates == ''" class="errors">يرجى اختيار احدى الخيارات</p>
          </div>
        </div>
      </div>
      <!-- END gender & maritalStates -->

      <hr>

      <!-- START childernNmber & status -->
      <div class="section">
        <!-- childernNmber -->
        <div class="space-y-2">
          <label class="pr-2" for="childernNmber">عدد الأبناء إن وجد:</label>
          <div class="">
            <select class="select" id="childernNmber" name="childernNmber" v-model="childernNmber"
              v-validate="'required'">
              <option value="" disabled selected>عدد الأبناء</option>
              <option value="بدون">بدون</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>
          <p class="errors">{{ errors.first('childernNmber') }}</p>
          <div v-if="showValidationError">
            <p v-if="childernNmber === ''" class="errors">يرجى اختيار عدد الأبناء</p>
          </div>
        </div>
        <!-- status -->
        <div class="space-y-2">
          <label class="pr-2" for="status">الحالة:</label>
          <div class="">
            <select class="select" id="status" name="status" v-model="status" v-validate="'required'">
              <option value="" disabled selected>الحالة</option>
              <option value="سعودي">سعودي</option>
              <option value="زوج مواطنة">زوج مواطنة</option>
              <option value="ابن مواطنة">ابن مواطنة</option>
              <option value="زوجة مواطن">زوجة مواطن</option>
              <option value="مقيم">مقيم</option>
            </select>
          </div>
          <p class="errors">{{ errors.first('status') }}</p>
          <div v-if="showValidationError">
            <p v-if="status === ''" class="errors">يرجى اختيار احدى الخيارات </p>
          </div>
        </div>
      </div>
      <!-- END START childernNmber & status -->

      <hr>

      <!-- START birthDate & birthPlace-->
      <div class="section">
        <!-- birthDate -->
        <div class="space-y-2">
          <label class="pr-2" for="birthDate">تاريخ الميلاد:</label>
          <div class="mt-1">
            <input class="input" type="date" name="birthDate" id="birthDate" v-bind:max="maxDate" v-model="birthDate"
              v-validate="'required'">
          </div>
          <p class="errors">{{ errors.first('birthDate') }}</p>
          <div v-if="showValidationError">
            <p v-if="birthDate == '' && errors.first('birthDate') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- birthPlace -->
        <div class="space-y-2">
          <label class="pr-2" for="birthPlace">مكان الميلاد:</label>
          <div class="mt-1">
            <input class="input" type="text" name="birthPlace" id="birthPlace" v-model="birthPlace"
              placeholder="مكان الميلاد" v-validate="'required|alpha_spaces|min:3'">
          </div>
          <p class="errors">{{ errors.first('birthPlace') }}</p>
          <div v-if="showValidationError">
            <p v-if="birthPlace == '' && errors.first('birthPlace') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- End birthDate & birthPlace- -->

      <hr>

      <!-- START nationality & idNumber-->
      <div class="section">
        <!-- nationality -->
        <div class="space-y-2">
          <label class="pr-2" for="nationality">الجنسية:</label>
          <div class="mt-1">
            <input class="input" type="text" name="nationality" id="nationality" v-model="nationality"
              placeholder="الجنسية" v-validate="'required|alpha_spaces|min:3'">
          </div>
          <p class="errors">{{ errors.first('nationality') }}</p>
          <div v-if="showValidationError">
            <p v-if="nationality == '' && errors.first('nationality') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- idNumber -->
        <div class="space-y-2">
          <label class="pr-2" for="idNumber">رقم الهوية/الإقامة:</label>
          <div class="mt-1">
            <input class="input" type="text" name="idNumber" id="idNumber" v-model="idNumber"
              placeholder="رقم الهوية/الإقامة" v-validate="'required|numeric|min:10|max:10'">
          </div>
          <p class="errors">{{ errors.first('idNumber') }}</p>
          <div v-if="showValidationError">
            <p v-if="idNumber == '' && errors.first('idNumber') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END nationality & idNumber-->

      <hr>

      <!-- START issuePlace & email-->
      <div class="section">
        <!-- issuePlace -->
        <div class="space-y-2">
          <label class="pr-2" for="issuePlace">مكان الإصدار:</label>
          <div class="mt-1">
            <input class="input" type="text" name="issuePlace" id="issuePlace" v-model="issuePlace"
              placeholder="مكان الإصدار" v-validate="'required|alpha_spaces|min:3'">
          </div>
          <p class="errors">{{ errors.first('issuePlace') }}</p>
          <div v-if="showValidationError">
            <p v-if="issuePlace == '' && errors.first('issuePlace') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- email -->
        <div class="space-y-2">
          <label class="pr-2" for="email">البريد الاكتروني:</label>
          <div class="mt-1">
            <input class="input" type="text" name="email" id="email" v-model="email" placeholder="البريد الإلكتروني"
              v-validate="'required|email'">
          </div>
          <p class="w-[300px] text-red-400">{{ errors.first('email') }}</p>
          <div v-if="showValidationError">
            <p v-if="email == '' && errors.first('email') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END issuePlace & email-->

      <hr>

      <!-- START residencePlace & homeAddress-->
      <div class="section">
        <!-- residencePlace -->
        <div class="space-y-2">
          <label class="pr-2" for="residencePlace">مدينة السكن:</label>
          <div class="mt-1">
            <input class="input" type="text" name="residencePlace" id="residencePlace" v-model="residencePlace"
              placeholder="مدينة السكن" v-validate="'required|alpha_spaces|min:3'">
          </div>
          <p class="errors">{{ errors.first('residencePlace') }}</p>
          <div v-if="showValidationError">
            <p v-if="residencePlace == '' && errors.first('residencePlace') == null" class="errors
            ">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- homeAddress -->
        <div class="space-y-2">
          <label class="pr-2" for="homeAddress">عنوان السكن:</label>
          <div class="mt-1">
            <input class="input" type="text" name="homeAddress" id="homeAddress" v-model="homeAddress"
              placeholder="عنوان السكن" v-validate="'required|min:3'">
          </div>
          <p class="errors">{{ errors.first('homeAddress') }}</p>
          <div v-if="showValidationError">
            <p v-if="homeAddress == '' && errors.first('homeAddress') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END residencePlace & homeAddress-->

      <hr>

      <!-- START mobileNumber & alternativeMobileNumber-->
      <div class="section">
        <!-- mobileNumber -->
        <div class="space-y-2">
          <label class="pr-2" for="mobileNumber">رقم الجوال:</label>
          <div class="mt-1">
            <input class="input" type="text" name="mobileNumber" id="mobileNumber" v-model="mobileNumber"
              placeholder="رقم الجوال" v-validate="'required|numeric|min:10|max:10'">
          </div>
          <p class="errors">{{ errors.first('mobileNumber') }}</p>
          <div v-if="showValidationError">
            <p v-if="mobileNumber == '' && errors.first('mobileNumber') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- alternativeMobileNumber -->
        <div class="space-y-2">
          <label class="pr-2" for="alternativeMobileNumber">رقم الجوال البديل:</label>
          <div class="mt-1">
            <input class="input" type="text" name="alternativeMobileNumber" id="alternativeMobileNumber"
              v-model="alternativeMobileNumber" placeholder="رقم الجوال البديل"
              v-validate="'required|numeric|min:10|max:10'">
          </div>
          <p class="errors">{{ errors.first('alternativeMobileNumber') }}</p>
          <div v-if="showValidationError">
            <p v-if="alternativeMobileNumber == '' && errors.first('alternativeMobileNumber') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END mobileNumber & alternativeMobileNumber-->

      <hr>

      <!-- START appliedJob & preferredWorkCity-->
      <div class="section">
        <!-- appliedJob -->
        <div class="space-y-2">
          <label class="pr-2" for="appliedJob">الوظيفة المقدم عليها:</label>
          <div class="mt-1">
            <input class="input" type="text" name="appliedJob" id="appliedJob" v-model="appliedJob"
              placeholder="الوظيفة المقدم عليها" v-validate="'required|alpha_spaces'">
          </div>
          <p class="errors">{{ errors.first('appliedJob') }}</p>
          <div v-if="showValidationError">
            <p v-if="appliedJob == '' && errors.first('appliedJob') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- preferredWorkCity -->
        <div class="space-y-2">
          <label class="pr-2" for="preferredWorkCity">مدينة العمل المفضلة:</label>
          <div class="mt-1">
            <input class="input" type="text" name="preferredWorkCity" id="preferredWorkCity" v-model="preferredWorkCity"
              placeholder="مدينة العمل المفضلة" v-validate="'required|alpha_spaces|min:3'">
          </div>
          <p class="errors">{{ errors.first('preferredWorkCity') }}</p>
          <div v-if="showValidationError">
            <p v-if="preferredWorkCity == '' && errors.first('preferredWorkCity') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
      </div>
      <!-- END appliedJob & preferredWorkCity-->

      <!-- THE BUTTON -->
      <div class="flex justify-center gap-6">
        <div class="w-[300px] text-left">
          <button @click="handlePrevious"
            class="bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer">الخطوة
            السابقة</button>
        </div>
        <div class="w-[300px] text-right">
          <button @click="handleNext" :class="{
            'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
            'bg-secondary': !isDataEmpty,
            'bg-slate-400': isDataEmpty,
            'cursor-auto': isDataEmpty
          }">
            الخطوة التالية
          </button>
        </div>
      </div>
      <!-- END OF THE BUTTON -->
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      gender: '',
      maritalStates: '',
      childernNmber: '',

      birthDate: '',
      birthPlace: '',
      status: '',
      nationality: '',
      maxDate: '2005-12-01',

      idNumber: '',
      issuePlace: '',
      residencePlace: '',
      homeAddress: '',

      mobileNumber: '',
      alternativeMobileNumber: '',
      appliedJob: '',
      preferredWorkCity: '',

      email: '',
      showValidationError: false
    }
  },
  methods: {
    handleNext() {
      if (this.name !== '' && this.email !== '' && this.gender !== '' && this.maritalStates !== '' &&
        this.childernNmber !== '' && this.status !== '' && this.birthDate !== '' && this.birthPlace !== '' &&
        this.nationality !== '' && this.idNumber !== '' && this.residencePlace !== '' && this.homeAddress !== '' &&
        this.mobileNumber !== '' && this.alternativeMobileNumber !== '' && this.appliedJob !== '' && this.preferredWorkCity !== '' &&
        this.issuePlace !== '' &&
        !this.errors.has('name') && !this.errors.has('email') && !this.errors.has('gender') && !this.errors.first('maritalStates') &&
        !this.errors.has('childernNmber') && !this.errors.has('status') && !this.errors.has('birthDate') && !this.errors.first('birthPlace') &&
        !this.errors.has('nationality') && !this.errors.has('idNumber') && !this.errors.has('residencePlace') && !this.errors.first('homeAddress') &&
        !this.errors.has('mobileNumber') && !this.errors.has('alternativeMobileNumber') && !this.errors.has('appliedJob') && !this.errors.first('preferredWorkCity') &&
        !this.errors.first('issuePlace')) {

        localStorage.setItem('name', this.name)
        localStorage.setItem('gender', this.gender)
        localStorage.setItem('maritalStates', this.maritalStates)
        localStorage.setItem('childernNmber', this.childernNmber)
        localStorage.setItem('birthDate', this.birthDate)
        localStorage.setItem('birthPlace', this.birthPlace)
        localStorage.setItem('status', this.status)
        localStorage.setItem('nationality', this.nationality)
        localStorage.setItem('idNumber', this.idNumber)
        localStorage.setItem('issuePlace', this.issuePlace)
        localStorage.setItem('residencePlace', this.residencePlace)
        localStorage.setItem('homeAddress', this.homeAddress)
        localStorage.setItem('mobileNumber', this.mobileNumber)
        localStorage.setItem('alternativeMobileNumber', this.alternativeMobileNumber)
        localStorage.setItem('appliedJob', this.appliedJob)
        localStorage.setItem('preferredWorkCity', this.preferredWorkCity)
        localStorage.setItem('email', this.email)

        this.$emit('update-step', 'step3'); // Emit an event to update the step in the parent component
      } else {
        this.showValidationError = true; // Set the flag to display the custom error message
      }
    },
    handlePrevious() {
      this.$emit('update-step', 'step1'); // Emit an event to update the step in the parent component
    },
  },
  computed: {
    isDataEmpty() {
      if (this.name == '' || this.email == '' || this.gender == '' || this.maritalStates == '' ||
        this.childernNmber == '' || this.status == '' || this.birthDate == '' || this.birthPlace == '' ||
        this.nationality == '' || this.idNumber == '' || this.residencePlace == '' || this.homeAddress == '' ||
        this.mobileNumber == '' || this.alternativeMobileNumber == '' || this.appliedJob == '' || this.preferredWorkCity == '' ||
        this.issuePlace == '' ||
        this.errors.has('name') || this.errors.has('email') || this.errors.has('gender') || this.errors.has('maritalStates') ||
        this.errors.has('childernNmber') || this.errors.has('status') || this.errors.has('birthDate') || this.errors.has('birthPlace') ||
        this.errors.has('nationality') || this.errors.has('idNumber') || this.errors.has('residencePlace') || this.errors.has('homeAddress') ||
        this.errors.has('mobileNumber') || this.errors.has('alternativeMobileNumber') || this.errors.has('residencePlace') || this.errors.has('homeAddress') ||
        this.errors.has('issuePlace')) {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
}
</script>

<style scoped>
.progress-bar {
  height: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  width: 100%;
  animation: progressAnimationStrike 2s;
}

.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}

.sc1 {
  animation: scale 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes scale {
  from {
    padding: 20px;
    background: transparent;
  }

  to {
    padding: 28px;
    background: #c0d889;
  }
}
</style>