<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['2'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['3'] after:text-white"></div>
      <div class="line">
        <div class="progress-bar"></div>
      </div>
      <div class="step bg-thired p-6 md:p-7 after:content-['4'] after:text-white after:text-lg md:after:text-2xl sc1">
      </div>
      <div class="line"></div>

      <div class="step border border-thired p-4 md:p-5 after:content-['5']">
      </div>
      <div class="line"></div>
      <div class="step border border-thired p-4 md:p-5 after:content-['6']"></div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 rounded-r bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 border-2 border-thired">
        <div class="progress-line"></div>
      </div>
      <div class="h-2 w-10 border-2 border-thired"></div>
      <div class="h-2 w-10 rounded-l border-2 border-thired"></div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">

      <!-- START THE DIV OF THE HAS EXPERINCE -->
      <div class="space-y-2 text-lg w-[300px] mx-auto md:w-auto">
        <label class="" for="">هل لديك خبرة عملية في الوظيفة المتقدم عليها؟</label>
        <div class="flex items-center gap-x-3">
          <input value="نعم" v-validate.continues="'required'" type="radio" name="has_experince_yes"
            class="h-4 w-4 border-gray-300 text-secondary" v-model="has_experince">
          <label>نعم</label>
        </div>
        <div class="flex items-center gap-x-3">
          <input value="لا" v-validate.continues="'required'" type="radio" name="has_experince_no"
            class="h-4 w-4 border-gray-300 text-secondary" v-model="has_experince">
          <label>لا</label>
        </div>
        <p class="errors">{{ errors.first('has_experience_yes') }}</p>
        <p class="errors">{{ errors.first('has_experience_no') }}</p>
        <div v-if="showValidationError">
          <p v-if="has_experince == ''" class="errors">يرجى اختيار خيار واحد على الأقل</p>
        </div>
      </div>
      <!-- END OF THE DIV OF THE HAS EXPERINCE -->

      <hr>

      <div class="section">
        <!-- THE YEARS OF EXPERIENCE DIV -->
        <div class="space-y-2 w-[300px]">
          <p class="pr-2 h-[52px]" for="">عدد سنوات الخبرة في نفس الوظيفة المتقدم عليها</p>
          <div>
            <input class="input" v-model="years_experience" name="years_experience"
              v-validate.continues="'required|min:1|numeric'" type="text">
          </div>
          <p class="errors">{{ errors.first('years_experience') }}</p>
          <div v-if="showValidationError">
            <p v-if="years_experience == '' && errors.first('years_experience') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF THE YEARS OF EXPERIENCE DIV -->

        <!-- THE Job title DIV -->
        <div class="space-y-2 w-[300px]">
          <p class="pr-2 md:h-[52px]" for="">المسمى الوظيفي لآخر وظيفة</p>
          <div>
            <input class="input" v-model="job_title" name="job_title" v-validate.continues="'required|min:3'" type="text">
          </div>
          <p class="errors">{{ errors.first('job_title') }}</p>
          <div v-if="showValidationError">
            <p v-if="job_title == '' && errors.first('job_title') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF Job title DIV -->
      </div>

      <hr>

      <div class="section">
        <!-- THE WHAT EXPERIENCE DIV -->
        <div class="space-y-2 w-[300px]">
          <p class="h-[80px]" for="">تسلسل الخبرات الوظيفية في حال تعددها، والخبرات المكتسبة في كل وظيفة</p>
          <div>
            <textarea class="input" name="what_experience" id="" cols="30" rows="6" v-model="what_experience"
              v-validate.continues="'required|min:3'"></textarea>
          </div>
          <p class="errors">{{ errors.first('what_experience') }}</p>
          <div v-if="showValidationError">
            <p v-if="what_experience == '' && errors.first('what_experience') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF WHAT EXPERIENCE DIV -->

        <!-- THE SOFT SKILLS DIV -->
        <div class="space-y-2 w-[300px]">
          <p class="md:h-[80px]" for="">المهارات الوظيفية (في غير مجال الوظيفة المتقدم عليها ) بالتفصيل</p>
          <textarea class="input" name="soft_skills" id="" cols="30" rows="6" v-model="soft_skills"
            v-validate.continues="'required|min:3'"></textarea>
          <p class="errors">{{ errors.first('soft_skills') }}</p>
          <div v-if="showValidationError">
            <p v-if="soft_skills == '' && errors.first('soft_skills') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF SOFT SKILLS DIV -->
      </div>

      <hr>

      <div class="section">
        <!-- THE Candidate's message DIV -->
        <div class="flex flex-col gap-2">
          <label class="pr-2" for="">رسالة المرشح لجهة التوظيف</label>
          <textarea class="input" name="candidate_message" id="" cols="30" rows="6" v-model="candidate_message"
            v-validate.continues="'required|min:3'"></textarea>
          <p class="errors">{{ errors.first('candidate_message') }}</p>
          <div v-if="showValidationError">
            <p v-if="candidate_message == '' && errors.first('candidate_message') == null" class="errors">يرجى تعبئة الحقل</p>
          </div>
        </div>
        <!-- END OF Candidate's message DIV -->
      </div>



      <!-- THE BUTTON -->
      <div class="flex justify-center gap-6">
        <div class="w-[300px] text-left">
          <button @click="handlePrevious"
            class="bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer">الخطوة
            السابقة</button>
        </div>
        <div class="w-[300px] text-right">
          <button @click="handleNext" :class="{
            'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
            'bg-secondary': !isDataEmpty,
            'bg-slate-400': isDataEmpty,
            'cursor-auto': isDataEmpty
          }">
            الخطوة التالية
          </button>
        </div>
      </div>
      <!-- END OF THE BUTTON -->
    </div>

  </div>
</template>


<script>
export default {
  name: 'step-four',
  data() {
    return {
      has_experince: '',
      years_experience: '',
      job_title: '',
      what_experience: '',
      soft_skills: '',
      candidate_message: '',
      showValidationError: false
    };
  },
  methods: {
    handleNext() {
      if (this.has_experince !== '' && this.years_experience !== '' && this.job_title !== '' && this.what_experience !== '' &&
        this.soft_skills !== '' && this.candidate_message !== '') {

        localStorage.setItem('has_experince', this.has_experince)
        localStorage.setItem('years_experience', this.years_experience)
        localStorage.setItem('job_title', this.job_title)
        localStorage.setItem('what_experience', this.what_experience)
        localStorage.setItem('soft_skills', this.soft_skills)
        localStorage.setItem('candidate_message', this.candidate_message)

        this.$emit('update-step', 'step5')
      } else {
        this.showValidationError = true; // Set the flag to display the custom error message
      }
    },

    handlePrevious() {
      this.$emit('update-step', 'step3'); // Emit an event to update the step in the parent component
    },

  },
  computed: {
    isDataEmpty() {
      if (this.has_experince == '' || this.years_experience == '' || this.job_title == '' || this.what_experience == '' ||
        this.soft_skills == '' || this.candidate_message == '') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>


<style scoped>
.progress-bar {
  height: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  width: 100%;
  animation: progressAnimationStrike 2s;
}

.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}

.sc1 {
  animation: scale 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes scale {
  from {
    padding: 20px;
    background: transparent;
  }

  to {
    padding: 28px;
    background: #c0d889;
  }
}
</style>