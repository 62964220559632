<template>
  <div class="flex flex-col justify-center items-center gap-4 mb-10">

    <!-- step -->
    <div class="hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0">
      <div class="step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['2'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['3'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['4'] after:text-white"></div>
      <div class="w-16 md:w-24 h-1 bg-thired"></div>
      <div class="step bg-thired p-4 md:p-5 after:content-['5'] after:text-white"></div>
      <div class="line">
        <div class="progress-bar"></div>
      </div>
      <div class="step bg-thired p-6 md:p-7 after:content-['6'] after:text-white after:text-lg md:after:text-2xl sc1">
      </div>
    </div>

    <!-- step -->
    <div class="flex justify-center items-center mb-12 mt-16 gap-2 md:hidden">
      <div class="h-2 w-10 rounded-r bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 bg-thired"></div>
      <div class="h-2 w-10 border-2 rounded-l border-thired">
        <div class="progress-line"></div>
      </div>
    </div>

    <!-- input -->
    <div class="space-y-6 text-lg">

      <div class="section">
        <!-- START THE DIV OF THE acceptCondtion -->
        <form action="https://sheetdb.io/api/v1/u2p6ttr5t0iie" method="post">
          <div class="space-y-2 w-[300px] md:w-full">
            <div class="flex justify-center items-center text-right mb-10 md:w-[700px]">
              <p><span class="font-bold">عزيزي المتقدم للوظيفة:</span>
                إن سياسة التوظيف في شركة خطوة سريعة لحاضنات ومسرعات الاعمال مبنية على احتياجاتها من الكفاءات التي تفي
                بمتطلبات وظائف محددة، ونتطلع إلى توظيف من تتوافق مؤهلاته وخبراته مع الاحتياجات التي تتطلبها الوظيفة حسب
                تقدير جهة التوظيف في المرحلة الأولية، ثم تجاوز اختبارات والمقابلة الشخصية في المراحل اللاحقة. ولهذا فإن
                التوظيف لا يتم بحسب أولوية تقديم الطلب؛ بل وفقاً للوظايف الشاغرة ومتطلبات العمل، مع خالص شكرنا وتقديرنا
                لجميع المتقدمين.

                وأتعهد بأن جميع المعلومات المدخلة دقيقة، وأعد مسؤولاً عن صحتها وعن سلامة الملفات المحملة على الموقع، وأخول
                جهة التوظيف استخدامها في تقييم طلب التوظيف، كما أن لجهة التوظيف الحق في استبعاد الطلب، إذا كان مكرراً أو
                احتوى على بيانات غير صالحة.
              </p>
            </div>
            <div class="flex align-center justify-center gap-3"> <!-- Add align-start class -->
              <input v-validate.continues="'required'" type="radio" value="accepted" v-model="accepted"
                name="acceptCondtion" class="cursor-pointer">
              <label class="" for="">الموافقة على شروط التقديم</label>
            </div>
            <p class="errors">{{ errors.first('acceptCondtion') }}</p>
            <div v-if="showValidationError" class="text-center">
              <p v-if="accepted == ''" class="errors mx-auto">يرجى الموافقة على الشروط</p>
            </div>
          </div>
        </form>
        <!-- END OF THE DIV OF THE acceptCondtion -->
      </div>


      <!-- THE BUTTON -->
      <div class="flex justify-center gap-6">
        <div class="w-[300px] text-left">
          <button @click="handlePrevious"
            class="bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer">الخطوة
            السابقة</button>
        </div>
        <div class="w-[300px] text-right">
          <button @click="submitForm" :class="{
            'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
            'bg-secondary': !isDataEmpty,
            'bg-slate-400': isDataEmpty,
            'cursor-pointer': !isDataEmpty
          }">
            الخطوة التالية
          </button>
        </div>
      </div>

      <!-- END OF THE BUTTON -->
    </div>

  </div>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
export default {
  name: 'step-five',
  data() {
    return {
      accepted: '',
      showValidationError: false
    };
  },
  methods: {

    async submitForm() {
      if (this.accepted == null || this.accepted == '') {
        this.showValidationError = true; // Set the flag to display the custom error message
      } else {
        try {
          if (this.accepted != null || this.accepted != '') {
            const formData = {
              'نطاق المتقدم للوظيفة': localStorage.getItem('selectedOption'),
              'الاسم': localStorage.getItem('name'),
              'الجنس': localStorage.getItem('gender'),
              'الحالة الاجتماعية': localStorage.getItem('maritalStates'),
              'عدد الابناء': localStorage.getItem('childernNmber'), // Note: Corrected typo in the key
              'تاريخ الميلاد': localStorage.getItem('birthDate'),
              'مكان الميلاد': localStorage.getItem('birthPlace'),
              'الحالة': localStorage.getItem('status'),
              'الجنسية': localStorage.getItem('nationality'),
              'رقم الهوية-الاقامة': localStorage.getItem('idNumber'),
              'مكان الاصدار': localStorage.getItem('issuePlace'),
              'مدينة السكن': localStorage.getItem('residencePlace'),
              'عنوان السكن': localStorage.getItem('homeAddress'),
              'رقم الجوال': localStorage.getItem('mobileNumber'),
              'رقم الجوال البديل': localStorage.getItem('alternativeMobileNumber'),
              'الوظيفة المقدم عليها': localStorage.getItem('appliedJob'),
              'مدينة العمل المفضلة': localStorage.getItem('preferredWorkCity'),
              'البريد الالكتروني': localStorage.getItem('email'),

              'المؤهل الدراسي': localStorage.getItem('selectedQualification'),
              'التخصص': localStorage.getItem('specialization'),
              'مصدر المؤهل': localStorage.getItem('specialization_resource'), // Note: Corrected typo in the key
              'طريقة الدراسة': localStorage.getItem('studyMode'),
              'الدولة': localStorage.getItem('country'),
              'المدينة': localStorage.getItem('city'),
              'تاريخ التخرج': localStorage.getItem('graduation_date'), // Note: Corrected typo in the key
              'المعدل التراكمي': localStorage.getItem('gpa'),
              'التقدير': localStorage.getItem('academic_appreciation'),
              'هل يوجد خبرة': localStorage.getItem('has_experince'), // Note: Corrected typo in the key
              'سنوات الخبرة': localStorage.getItem('years_experience'),
              'المسمى الوظيفي لاخر وظيفة': localStorage.getItem('job_title'), // Note: Corrected typo in the key
              'الخبرات الوظيفية': localStorage.getItem('what_experience'),
              'المهارات الوظيفية': localStorage.getItem('soft_skills'), // Note: Corrected typo in the key
              'رسالة المرشح': localStorage.getItem('candidate_message'), // Note: Corrected typo in the key
              'الهوية الوطنية-الاقامة': localStorage.getItem('national_id'), // Note: Corrected typo in the key
              'العنوان الوطني': localStorage.getItem('national_address'), // Note: Corrected typo in the key
              'شهادة المؤهل': localStorage.getItem('certificate'),
              'السيرة الذاتية والخبرات': localStorage.getItem('resumeAndExperince'), // Note: Corrected typo in the key
            };
            // Make a POST request to the SheetDB API endpoint
            await axios.post('https://sheetdb.io/api/v1/hk1flljpameee', formData ,
            {
              auth: {
                username: '88cz79he',
                password: 'mjl7vyf2d2hoj8lgmnjk'
              }
            });
            // Handle the response as needed
            Swal.fire({
              icon: 'success',
              title: "تم ارسال البيانات بنجاح",
              text: ".شكرًا لك، نتمنى لك يوما سعيدا",
              showConfirmButton: false,
              timer: 6000
            })
            localStorage.clear();
            this.$emit('update-step', 'final');
          }
        } catch (error) {
          console.log('Error during form submission:', error);
          Swal.fire({
            icon: "error",
            title: "...خطأ",
            text: "حدث خطأ اثناء الارسال الرجاء التواصل مع المنشأة",
            confirmButtonText: "حسنا",
            confirmButtonColor: "#57b6b2",
            confirmButtonBorderColor: "none",
            confirmButtonBoxShadow: "none",
            confirmButtonOutline: "none"
          });
        }
      }

    },

    handlePrevious() {
      this.$emit('update-step', 'step5'); // Emit an event to update the step in the parent component
    },


  },
  computed: {
    isDataEmpty() {
      if (this.accepted == '') {
        return true
      } else {
        return false
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>


<style scoped>
.progress-bar {
  height: 4px;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  width: 100%;
  animation: progressAnimationStrike 2s;
}

.progress-line {
  height: 100%;
  width: 100%;
  transition: 0.4s linear;
  transition-property: width, background-color;
  background-color: #c0d889;
  animation: progressAnimationStrike 3s;
}

.sc1 {
  animation: scale 2s;
}

@keyframes progressAnimationStrike {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes scale {
  from {
    padding: 20px;
    background: transparent;
  }

  to {
    padding: 28px;
    background: #c0d889;
  }
}
</style>