var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-center items-center gap-4 mb-10"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"space-y-6 text-lg"},[_c('div',{staticClass:"section"},[_c('form',{attrs:{"action":"https://sheetdb.io/api/v1/u2p6ttr5t0iie","method":"post"}},[_c('div',{staticClass:"space-y-2 w-[300px] md:w-full"},[_vm._m(2),_c('div',{staticClass:"flex align-center justify-center gap-3"},[_c('input',{directives:[{name:"validate",rawName:"v-validate.continues",value:('required'),expression:"'required'",modifiers:{"continues":true}},{name:"model",rawName:"v-model",value:(_vm.accepted),expression:"accepted"}],staticClass:"cursor-pointer",attrs:{"type":"radio","value":"accepted","name":"acceptCondtion"},domProps:{"checked":_vm._q(_vm.accepted,"accepted")},on:{"change":function($event){_vm.accepted="accepted"}}}),_c('label',{attrs:{"for":""}},[_vm._v("الموافقة على شروط التقديم")])]),_c('p',{staticClass:"errors"},[_vm._v(_vm._s(_vm.errors.first('acceptCondtion')))]),(_vm.showValidationError)?_c('div',{staticClass:"text-center"},[(_vm.accepted == '')?_c('p',{staticClass:"errors mx-auto"},[_vm._v("يرجى الموافقة على الشروط")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"flex justify-center gap-6"},[_c('div',{staticClass:"w-[300px] text-left"},[_c('button',{staticClass:"bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer",on:{"click":_vm.handlePrevious}},[_vm._v("الخطوة السابقة")])]),_c('div',{staticClass:"w-[300px] text-right"},[_c('button',{class:{
          'bg-secondary hover:bg-opacity-70 py-2 px-5 rounded text-white font-bold cursor-pointer': true,
          'bg-secondary': !_vm.isDataEmpty,
          'bg-slate-400': _vm.isDataEmpty,
          'cursor-pointer': !_vm.isDataEmpty
        },on:{"click":_vm.submitForm}},[_vm._v(" الخطوة التالية ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden md:flex md:justify-center md:items-center md:mb-12 md:mt-16 lg:mt-0"},[_c('div',{staticClass:"step bg-thired p-4 md:p-5 after:content-['1'] after:text-white"}),_c('div',{staticClass:"w-16 md:w-24 h-1 bg-thired"}),_c('div',{staticClass:"step bg-thired p-4 md:p-5 after:content-['2'] after:text-white"}),_c('div',{staticClass:"w-16 md:w-24 h-1 bg-thired"}),_c('div',{staticClass:"step bg-thired p-4 md:p-5 after:content-['3'] after:text-white"}),_c('div',{staticClass:"w-16 md:w-24 h-1 bg-thired"}),_c('div',{staticClass:"step bg-thired p-4 md:p-5 after:content-['4'] after:text-white"}),_c('div',{staticClass:"w-16 md:w-24 h-1 bg-thired"}),_c('div',{staticClass:"step bg-thired p-4 md:p-5 after:content-['5'] after:text-white"}),_c('div',{staticClass:"line"},[_c('div',{staticClass:"progress-bar"})]),_c('div',{staticClass:"step bg-thired p-6 md:p-7 after:content-['6'] after:text-white after:text-lg md:after:text-2xl sc1"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center items-center mb-12 mt-16 gap-2 md:hidden"},[_c('div',{staticClass:"h-2 w-10 rounded-r bg-thired"}),_c('div',{staticClass:"h-2 w-10 bg-thired"}),_c('div',{staticClass:"h-2 w-10 bg-thired"}),_c('div',{staticClass:"h-2 w-10 bg-thired"}),_c('div',{staticClass:"h-2 w-10 bg-thired"}),_c('div',{staticClass:"h-2 w-10 border-2 rounded-l border-thired"},[_c('div',{staticClass:"progress-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center items-center text-right mb-10 md:w-[700px]"},[_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v("عزيزي المتقدم للوظيفة:")]),_vm._v(" إن سياسة التوظيف في شركة خطوة سريعة لحاضنات ومسرعات الاعمال مبنية على احتياجاتها من الكفاءات التي تفي بمتطلبات وظائف محددة، ونتطلع إلى توظيف من تتوافق مؤهلاته وخبراته مع الاحتياجات التي تتطلبها الوظيفة حسب تقدير جهة التوظيف في المرحلة الأولية، ثم تجاوز اختبارات والمقابلة الشخصية في المراحل اللاحقة. ولهذا فإن التوظيف لا يتم بحسب أولوية تقديم الطلب؛ بل وفقاً للوظايف الشاغرة ومتطلبات العمل، مع خالص شكرنا وتقديرنا لجميع المتقدمين. وأتعهد بأن جميع المعلومات المدخلة دقيقة، وأعد مسؤولاً عن صحتها وعن سلامة الملفات المحملة على الموقع، وأخول جهة التوظيف استخدامها في تقييم طلب التوظيف، كما أن لجهة التوظيف الحق في استبعاد الطلب، إذا كان مكرراً أو احتوى على بيانات غير صالحة. ")])])
}]

export { render, staticRenderFns }