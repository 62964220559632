<template>
  <div id="app" dir="rtl">
    <formLogo class="md:-mt-16"></formLogo>
    <keep-alive>
      <component :is="step" @update-step="updateStep" />
    </keep-alive>
  </div>
</template>

<script>
import formLogo from '@/components/formLogo'
import step1 from '@/components/formStep1'
import step2 from '@/components/formStep2'
import step3 from '@/components/formStep3'
import step4 from '@/components/formStep4'
import step5 from '@/components/formStep5'
import step6 from '@/components/formStep6'
import final from '@/components/formFinal'


export default {
  components: {
    formLogo,
    step1,
    step2,
    step3,
    step4,
    step5,
    step6,
    final
  },
  data() {
    return {
      step: 'step1',
    };
  },
  methods: {
    updateStep(newStep) {
      this.step = newStep;
    },
  },
  comments: {
  }

}
</script>

