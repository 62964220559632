import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VeeValidate from 'vee-validate';

import { Validator } from 'vee-validate';

Vue.config.productionTip = false;

const dictionary = {
  en: {
    messages:{
      alpha: () => 'Some English Message'
    }
  },
  ar: {
    messages: {
      required: 'هذا الحقل مطلوب',
      alpha_spaces: 'هذا الحقل لايقبل سوى حروف عربية',
      email: 'أدخل ايميل صالح',
      min: 'الحقل لا يقبل حروف اقل من 3',
      numeric: 'هذا الحقل لا يقبل سوى أرقام',

      alpha: 'هذا الحقل لايقبل سوى حروف عربية',
    },
    custom: {
      fullName: {
        min: 'الحقل لا يقبل حروف اقل من 10',
      },
      idNumber: {
        min: 'الحقل لا يقبل حروف اقل من 10',
        max: 'الحقل لا يقبل حروف اكثر من 10',
      },
      mobileNumber: {
        min: 'الحقل لا يقبل حروف اقل من 10',
        max: 'الحقل لا يقبل حروف اكثر من 10',
      },
      alternativeMobileNumber: {
        min: 'الحقل لا يقبل حروف اقل من 10',
        max: 'الحقل لا يقبل حروف اكثر من 10',
      },
      gpa: {
        min_value: 'الحقل يجب ان يكون 0 او اعلى',
        max_value: 'الحقل يجب ان يكون 100 او اقل'
      },
      years_experience: {
        min: 'الحقل لا يقبل حروف اقل من 1',
      },
    }
  },
};
// Override and merge the dictionaries
Validator.localize(dictionary);
const validator = new Validator();
validator.localize('ar');

// vee validate
const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errors', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'ar',
  validity: false,
  useConstraintAttrs: true
};

Vue.use(VeeValidate, config);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
