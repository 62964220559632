<template>
  <div>
    <img class=" relative md:top-7 lg:top-0  lg:scale-50" src="../assets/logo.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style></style>
